/** @jsx jsx */
import { jsx } from "theme-ui"
import loadable from "@loadable/component"
import Container from "../../../layout/container"
import { BannerHeight } from "../../../components/shared/constants/layout"

const PluginSearchBar = loadable(() => import(`./plugin-searchbar-body`))

const PageWithPluginSearchBar = ({ location, children, isPluginsIndex }) => (
  <Container
    css={theme => ({
      paddingLeft: 0,
      paddingRight: 0,
      [theme.mediaQueries.tablet]: {
        display: "flex",
        alignItems: "flex-start",
      },
    })}
  >
    <nav
      css={theme => ({
        // mobile: hide PluginSearchBar when on gatsbyjs.org/packages/foo, aka package README page
        display: !isPluginsIndex ? `none` : false,
        width: `100%`,
        zIndex: 1,
        minWidth: 0,
        [theme.mediaQueries.tablet]: {
          backgroundColor: theme.colors.primaryBackground,
          display: `block`,
          flexShrink: 0,
          width: "21rem",
          position: "sticky",
          // hard-coded to match nav without subnav
          top: 66,
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
          maxHeight: "calc(100vh - 66px)",

          ".withBanner &": {
            // hard-coded to match header with sticky banner
            top: `calc(66px + ${BannerHeight})`,
          },
        },
        [theme.mediaQueries.desktop]: {
          width: "24rem",
        },
      })}
      aria-label="Plugin navigation"
    >
      <PluginSearchBar location={location} />
    </nav>
    <main
      id="plugin-main"
      className="main-body"
      sx={theme => ({
        // mobile: hide README on gatsbyjs.org/plugins index page
        display: isPluginsIndex ? `none` : false,
        minWidth: 0,
        width: "100%",
        [theme.mediaQueries.tablet]: {
          display: `block`,
        },
      })}
    >
      {children}
    </main>
  </Container>
)

export default PageWithPluginSearchBar

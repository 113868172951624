/** @jsx jsx */
import { jsx } from "theme-ui"
import { Global } from "@emotion/core"
import { graphql } from "gatsby"
import { Spacer } from "gatsby-interface"
import { FaUsers as CommunityIcon } from "react-icons/fa"
import { GoMarkGithub as GithubIcon } from "react-icons/go"
import Layout from "../../layout"
import SiteHead from "../../head"
import PageWithPluginSearchBar from "./components/page-with-plugin-searchbar"
import GatsbyIcon from "../../components/svg/gatsby-monogram"
import { globalStyles } from "../../utils/styles/global"

const mutedLinkMixin = {
  fontSize: 1,
  lineHeight: `solid`,
  py: 4,
  "&&": {
    border: 0,
    color: `text.secondary`,
    display: `flex`,
    fontWeight: `body`,
  },
  "&&:hover": {
    color: `purple.60`,
  },
}

const GatsbyPluginBadge = ({ isOfficial }) => {
  const Icon = isOfficial ? GatsbyIcon : CommunityIcon
  const title = isOfficial
    ? `Official Gatsby Plugin`
    : `Community Gatsby Plugin`
  const text = isOfficial ? `Official Plugin` : `Community Plugin`

  return (
    <div
      sx={{
        ...mutedLinkMixin,
        mr: 9,
        "&&": {
          border: 0,
          color: `text.secondary`,
          display: `flex`,
          fontWeight: `body`,
        },
        "&&:hover": {
          color: `text.secondary`,
        },
      }}
    >
      <span
        sx={{
          display: `inline-block`,
          mr: 3,
        }}
        title={title}
      >
        <Icon />
      </span>
      {text}
    </div>
  )
}

// TODO: find a better solution to handle edge cases
// remove as soon as possible
const TEMPORARY_MANAGED_EDGE_CASES = {
  [`gatsby-source-wordpress-experimental`]: `https://github.com/gatsbyjs/gatsby-source-wordpress-experimental`,
}

export function Head(props) {
  const title = props.data.npmPackage?.name
  const description =
    props.data.npmPackage?.readme?.childMarkdownRemark?.excerpt ||
    `Plugin information for ${title}`

  return <SiteHead {...props} title={title} description={description} />
}

export default function PackageReadmeTemplate({
  location,
  data: { npmPackage },
}) {
  const {
    readme: { childMarkdownRemark: readmePage },
    fields: { official: isOfficial },
    name: packageName,
  } = npmPackage

  let githubUrl

  if (TEMPORARY_MANAGED_EDGE_CASES[packageName]) {
    githubUrl = TEMPORARY_MANAGED_EDGE_CASES[packageName]
  } else if (isOfficial) {
    githubUrl = `https://github.com/gatsbyjs/gatsby/tree/master/packages/${packageName}`
  } else {
    githubUrl =
      npmPackage.repository?.url ?? `https://github.com/search?q=${packageName}`
  }

  return (
    <Layout pathname={location.pathname}>
      <Spacer size={10} />
      <PageWithPluginSearchBar location={location}>
        <Global styles={globalStyles} />
        <div
          css={theme => ({
            marginLeft: `auto`,
            marginRight: `auto`,
            padding: theme.space[7],
            position: `relative`,
            [theme.mediaQueries.desktop]: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: theme.space[10],
            },
          })}
        >
          <div
            sx={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              pb: 7,
              "&&:hover": {
                color: `inherit`,
              },
              a: {
                textDecoration: `none`,
              },
            }}
          >
            <div
              css={{
                display: `flex`,
                justifyContent: `space-between`,
              }}
            >
              <GatsbyPluginBadge isOfficial={isOfficial} />
              <a
                href={githubUrl}
                aria-labelledby="github-link-label"
                sx={mutedLinkMixin}
              >
                <GithubIcon focusable="false" sx={{ mr: 3 }} />
                <span id="github-link-label">View plugin on GitHub</span>
              </a>
            </div>
          </div>
          <div
            css={{
              position: `relative`,
              "a img": { marginBottom: 0 },
              "img[src*='img.shields.io']": { marginBottom: 0 },
            }}
            dangerouslySetInnerHTML={{ __html: readmePage.html }}
          />
        </div>
      </PageWithPluginSearchBar>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    npmPackage(slug: { eq: $slug }) {
      name
      keywords
      fields {
        official
      }
      repository {
        url
      }
      readme {
        childMarkdownRemark {
          html
          excerpt
          timeToRead
        }
      }
    }
  }
`
